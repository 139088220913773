import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration.svg";
import {ReactComponent as SvgDotPattern } from "images/dot-pattern.svg"

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
])

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);


export default ({
  subheading = "",
  heading = (
    <>
      Experienced <span tw="text-primary-500">CAs</span> are always taking care of all your <br/> <span tw="text-primary-500">Financial Needs.</span>
    </>
  ),
  description = "",
  primaryButtonText = "Learn More",
  primaryButtonUrl = "https://timerse.com",
  imageSrc = TeamIllustrationSrc,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true,
  desc,
  title,
  subtitle,
  cont,
  type
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image css={imageCss} src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            
            {title && <Heading>{title}</Heading>}
            {subtitle && <Subheading>{subtitle}</Subheading>}
            <p> {cont}</p>
           {type === 'gst' && 
            <div>
              <p>Businesses with a turnover of more than Rs. 40 lakhs* (Rs. 10 lakhs for NE and hill states) must register as a regular taxable individual under the GST regime. GST registration is the name of the registration method.</p>

              <p> GST registration is required for some businesses. It is an offence under GST to conduct business without first applying for GST, and there will be severe penalties if this occurs.</p>

                <p>The time it takes to register for GST is normally between 2 and 6 working days.</p>

              <p> Who have to apply for GST</p>

                <p>Individuals who were already enrolled under the pre-GST law (i.e., Excise, VAT, Service Tax etc.)</p>

                <p>Businesses with a turnover of more than Rs. 40 lakhs* are qualified (Rs. 10 Lakhs for North-Eastern States, J&K, Himachal Pradesh and Uttarakhand)</p>

              <p> Non-Resident taxable person / Casual taxable person</p>
                <p>Agents of a supplier & Input service distributor</p>
              <p> Those that are subject to the reverse charge process.</p>
              <p> a person who sells through an e-commerce aggregator</p>
              <p> Every e-commerce aggregator is different.</p>
                <p>Individual who, other than a registered taxable person, provides online information and database access or retrieval services to a person in India from a location outside India</p>
                <p>The CBIC has announced that the threshold turnover has been raised from Rs 20 lakhs to Rs 40 lakhs. The notification will take effect on April 1st, 2019.</p>
           </div>
           }

{type === 'gstfill' && 
            <div>
              <p>Businesses with a turnover of more than Rs. 40 lakhs* (Rs. 10 lakhs for NE and hill states) must register as a regular taxable individual under the GST regime. GST registration is the name of the registration method.</p>

              <p> GST registration is required for some businesses. It is an offence under GST to conduct business without first applying for GST, and there will be severe penalties if this occurs.</p>

                <p>The time it takes to register for GST is normally between 2 and 6 working days.</p>

              <p> Who have to apply for GST</p>

                <p>Individuals who were already enrolled under the pre-GST law (i.e., Excise, VAT, Service Tax etc.)</p>

                <p>Businesses with a turnover of more than Rs. 40 lakhs* are qualified (Rs. 10 Lakhs for North-Eastern States, J&K, Himachal Pradesh and Uttarakhand)</p>

              <p> Non-Resident taxable person / Casual taxable person</p>
                <p>Agents of a supplier & Input service distributor</p>
              <p> Those that are subject to the reverse charge process.</p>
              <p> a person who sells through an e-commerce aggregator</p>
              <p> Every e-commerce aggregator is different.</p>
                <p>Individual who, other than a registered taxable person, provides online information and database access or retrieval services to a person in India from a location outside India</p>
                <p>The CBIC has announced that the threshold turnover has been raised from Rs 20 lakhs to Rs 40 lakhs. The notification will take effect on April 1st, 2019.</p>
           </div>
           }

           {type === 'prop' && 
            <div>
              <p>A sole proprietorship corporation is one that is owned and managed by one person. This type of business can be formed in fifteen days, making it one of the most common types of businesses to start in the unorganized market, especially among merchants and small traders. Registration is not necessary for a sole proprietorship company because it is identified by other registrations, such as GST registrations. However, the obligation is infinite, and it does not exist indefinitely.</p>


              <p>A permit or license issued by local authorities in compliance with the Shop and Establishment Act. The Certificate of Practice, which is issued by the Institute of Chartered Accountants of India, is a certification issued by registering authorities. The Central Government or a State Government Authority/Department, for example, issues the registration/licensing document in the name of the patented concern. Banks may also recognize the IEC (Importer Exporter Code) given to a proprietary concern by the DGFT office as an identification document for opening a bank account, among other things.</p>


                
               </div>
           }

{type === 'partner' && 
            <div>
              <p>A General Partnership is a type of corporate structure in which two or more people administer and run a company in compliance with the terms and goals outlined in the Partnership Deed. Since the partners have unrestricted responsibility, which means they are collectively responsible for the business's obligations, this arrangement is believed to have lost its validity after the creation of the Joint Liability Partnership (LLP). However, because of the low prices, ease of configuration, and minimum regulatory requirements, it is a feasible choice for others, such as home companies who are unlikely to incur debt. General Partnerships are excluded from registration.

              </p>

              <p>A collaboration company is ideally suited for small firms that intend to stay small. It is a viable choice for such companies due to its low prices, ease of setup, and minimum regulatory criteria. General Partnerships are excluded from registration. Section 4 of the Relationship Act of 1932 controls it. With the advent of the Joint Liability Company, it has lost significance for bigger companies (LLP).. This is due to the fact that an LLP enjoys the reduced costs of a relationship while providing the privilege of unrestricted liability, which ensures the partners are not individually responsible for the business's debts. At least two partners are needed for a partnership company. A relationship firm in the banking industry can have up to ten partners, while those of any other company can have up to twenty partners.
                </p>

                
               </div>
           }

{type === 'ngo' && 
            <div>
              <p>Did you know charitable deeds and compassion can help you save tax? Section 80G of the Indian Income tax Act provides provisions for that. As per 80G, you can deduct your donations to Central and State Relief Funds, NGOs and other charitable institutions from your total income to arrive at your taxable income.

In this article, we will tell you how and when to claim deductions on donations made to Charitable Trusts and NGOs.
              </p>

              <p>
              “The word ‘Charity’ connotes altruism in thought and action. It involves an idea of benefiting others rather than oneself” Supreme Court in the case Andhra Chamber of Commerce [1965] 55 ITR 722 (SC).  
              </p>

              <p>
              Charity is a voluntary help either in money or kind to the needy. Collective efforts are always more fruitful. Hence, there are various Non-Governmental Organizations (NGOs) and non-profit entities constantly working on charitable activities by raising funds all over the world by forming either an institution or trust. 
              </p>
              <p>
              Efforts of such institutions play a significant role in promoting economic development and the social welfare objectives of the Government. Their outreach and more localised approach helps to identify the needy and lend a supporting hand. For this reason, Indian government has provided various tax incentives and exemptions to charitable institutions, Section 80G being a significant one.
              </p>
                
               </div>
           }



{type === 'msme' && 
            <div>
              <p>MSME stands for Micro, Small and Medium Enterprises. In a developing country like India, MSME  industries are the backbone of the economy. When these industries grow, the economy of the country grows as a whole and flourishes. These industries are also known as small-scale industries or SSI’s.
              </p>

<p>
The MSME became operational on October 02, 2006. It was established to promote, facilitate and develop the competitiveness of the micro, small and medium enterprises.
</p>

<p>
What are Micro, Small and Medium Enterprise?
Previously the existing MSME classification was based on the criteria of investment in plant and machinery or equipment. So, to enjoy the MSME benefits, the MSMEs have to limit their investment to a lower limit, as mentioned below:
</p>
<p>
Now, under the Aatmanirbhar Bharat Abhiyan (ABA), the government revised the MSME classification by inserting composite criteria of both investment and annual turnover. Also, the distinction between the manufacturing and the services sectors under the MSME definition was removed. The following is the current revised MSME classification, where the investment and annual turnover are to be considered for deciding an MSME.


</p>
         
                
               </div>
           }

{type === 'startup' && 
            <div>
              <p>One of the most widely recommended ways to start a business in India is to form a Private Limited Company. This form of business provides limited liability for its members, as well as ownership limitations. An LLP is made up of partners who own and manage the business. Directors and shareholders can be different in a private limited company registry.
              </p>

              <p>
              We take care of all legal formalities and comply with all Ministry of Corporate Affairs requirements. You can obtain a Certificate of Incorporation, as well as your PAN and TAN, once the company registration process has been approved. You can now open a current bank account and start running your business.
              </p>

         
                
               </div>
           }

{type === 'fssai' && 
            <div>
              <p>
              Anyone who wants to start a business in the food industry, whether it's food production, manufacturing, packaging, or distribution ,restaurants must receive a 14-digit license number, which must be written on their food items Food Safety and Standard Authority of India must first register with the (FSSAI).
                 </p>

              <p>
              The FSSAI requires any food business operator to be licensed or registered. Small businesses, such as hawkers, petty retailers, and others, must register with the FSSAI if their annual turnover is less than Rs.12 lakhs. A FSSAI license is required for all food businesses with annual turnover exceeding Rs 12 lakhs.
               </p>

<p>
If your company is small or big, you must obtain an FSSAI State License or an FSSAI Central License, depending on your needs. Large producers, exporters, importers, and other large businesses usually receive a Central Permit, while mid-sized businesses, such as transporters and traders, need a State License.
</p>

<p>
The FSSAI license regulations are strict. Food industry FSSAI licenses are available for 1 to 5 years. It is required to apply for license renewal 30 days before the expiration of the current license. Non-renewal of licenses will result in a fine of Rs.100 every day before the renewal is finished. If a license is not renewed before the due date, it will become invalid and the company will have to apply for a new license.
</p>
         
                
               </div>
           }



{type === 'trademark' && 
            <div>
              <p>
              In layman's terms, trademarks are rare, one-of-a-kind signs that are used to distinguish products or services from a certain brand. They can take the form of designs, images, signals, or even gestures. It is important because it distinguishes the goods from the competition's. It can be associated with your brand or product. Trademarks are intellectual property and hence are shielded from misuse. The Trademark Act of 1999 protects trademarks and their rights.
               </p>

              <p>
              To obtain trademark protection, the trademark must be registered. It is important to register your trademark because it prohibits anyone from imitating your logo and misrepresenting such brands as yours. Trademarks allow shoppers to recognize a brand and its value with a single glance, such as the emblem of a tick sign for Nike or a leaping wildcat for Puma.
               </p>

<p>
Unlike patents, trademarks do not have a certain term of validity. Whereas a patent expires after 20 years, a trademark registration expires after ten years, but unlike trademarks, a trademark may be extended for another ten years. This procedure can be repeated forever, which means that as long as you keep renewing the patent, it will not expire and will continue to be protected by the Act.
</p>


         
                
               </div>
           }



{type === 'pf' && 
            <div>
              <p>
              Employees' Provident Fund [EPF] is a scheme established by the Employees' Provident Funds and Miscellaneous Provisions Act of 1952. It is governed by the Employees' Provident Fund Organisation (EPFO), which is one of the world's major Social Security Organizations in terms of clientele and number of financial transactions. Essentially, EPF is a service given by the organisation to an employee after their retirement.
               </p>

              <p>
              The employer must secure the registration within one month of achieving the strength, or face penalties. Even if the staff strength falls below the prescribed minimum, a licenced establishment remains subject to the Act. After providing at least two months' notice for compulsory registration, the Central Government may extend the provisions to any institution employing less than 20 people.
               </p>

<p>

The employer must secure the registration within one month of achieving the strength, or face penalties. Even if the staff strength falls below the prescribed minimum, a licenced establishment remains subject to the Act. After providing at least two months' notice for compulsory registration, the Central Government may extend the provisions to any institution employing less than 20 people.
</p>

<p>
Any establishments with less than 20 workers are still expected to register for PF, although this is a voluntary registry. Both workers will be liable for a PF from the start of their jobs, and the employer will be responsible for deducting and paying the PF.
</p>

<p>
The 12% PF share should be split evenly between the boss and the employee. The boss contributes 12% of the minimum wage. If the establishment has less than 20 workers, the PF deduction rate is 10%.
</p>
         
                
               </div>
           }



{type === 'esi' && 
            <div>
              <p>
              ESI stands for Employee State Insurance managed by the Employee State Insurance Corporation which is an autonomous body created by the law under the Ministry of Labour and Employment, Government of India
              </p>

              <p>
              This scheme was started for Indian workers. The workers are provided with a huge variety of medical, monetary and other benefits from the employer. Any non-seasonal factory and company having more than 10 employees (in some states it is 20 employees) who have a maximum salary of Rs. 21,000/- has to mandatorily register itself with the ESIC.
              </p>
                
<p>
Under this scheme, the employer needs to contribute an amount of 3.25% of the total monthly salary payable to the employee whereas the employer needs to contribute only 0.75% of his monthly salary every month of the year. The only exemption to the employee in paying his contribution is whose salary is less than Rs. 176/- per day.
</p>



               </div>
           }
            {/* <Description> */}
            {!desc &&  <ol>
            {/* <li>Call or <a href="https://wa.me/918700701156">WhatsApp us</a> on <span tw="text-primary-500"><a href="tel:+918700701156">(+91)8700701156</a></span> to know more about our services.</li>
            <li>100% Money Back Guarantee - If you are not satisfied with our services after filing for one month, we will return your full amount.</li> */}
            {/* <li>Open and Fixed Pricing, Same for all clients</li>
            <li> Timely Submissions</li>
            <li>Includes GSTR-2A Matching</li>
            <li>Includes Annual Return Filing (If you file with us for whole year)</li>
            <li>We sends complete calculation in excel (You will always be aware of what data you are filing)</li> */}
            </ol  >}
            {/* </Description> */}
            {/* <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
