import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 lg:mr-12 last:mr-0 text-gray-700 border-gray-400 hocus:border-gray-700 `;
const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "GST Registration",
      link: '/gst',
      price:'1399'
      //description: "Make your GST Filing simpler and faster with us.We will files all types of GST return applicable on you i.e GSTR-1, GSTR-3B, GSTR-9.We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    },
    
    {
      imageSrc: ShieldIconImage,
      title: "Proprietorship Registration",
      link: '/proprietorship',
      price: '1499'
      //description: "Make your GST Filing simpler and faster with us.We will files all types of GST return applicable on you i.e GSTR-1, GSTR-3B, GSTR-9.We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    },
   
    {
      imageSrc: ShieldIconImage,
      title: "Partnership Registration",
      link: '/partnership',
      price:'1499'
      //description: "Make your GST Filing simpler and faster with us.We will files all types of GST return applicable on you i.e GSTR-1, GSTR-3B, GSTR-9.We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    },
   
    {
      imageSrc: ShieldIconImage,
      title: "NGO Registration",
      link: '/ngo',
      price:'8999'
     // description: "Make your GST Filing simpler and faster with us.We will files all types of GST return applicable on you i.e GSTR-1, GSTR-3B, GSTR-9.We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    },
   
    {
      imageSrc: ShieldIconImage,
      title: "MSME Registration",
      link: '/msme',
      price:'799'
      //description: "Make your GST Filing simpler and faster with us.We will files all types of GST return applicable on you i.e GSTR-1, GSTR-3B, GSTR-9.We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    },
    {
      imageSrc: ShieldIconImage,
      title: "Startup Registration",
      link: '/startup',
      price:'1499'
      //description: "Make your GST Filing simpler and faster with us.We will files all types of GST return applicable on you i.e GSTR-1, GSTR-3B, GSTR-9.We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    },
    {
      imageSrc: ShieldIconImage,
      title: "FSSAI Registration",
      link: '/fssai',
      price:'1499'
      //description: "Make your GST Filing simpler and faster with us.We will files all types of GST return applicable on you i.e GSTR-1, GSTR-3B, GSTR-9.We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    },
    {
      imageSrc: ShieldIconImage,
      title: "Trade Mark Registration",
      link: '/trademark',
      price:'7999'
      //description: "Make your GST Filing simpler and faster with us.We will files all types of GST return applicable on you i.e GSTR-1, GSTR-3B, GSTR-9.We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    },
    {
      imageSrc: ShieldIconImage,
      title: "PF Registration",
      link: '/pf',
      price:'799'
     // description: "Make your GST Filing simpler and faster with us.We will files all types of GST return applicable on you i.e GSTR-1, GSTR-3B, GSTR-9.We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    },
   
    {
      imageSrc: ShieldIconImage,
      title: "ESI Registration",
      link: '/esi',
      price:'799'
    //  description: "Make your GST Filing simpler and faster with us.We will files all types of GST return applicable on you i.e GSTR-1, GSTR-3B, GSTR-9.We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    },

    {
      imageSrc: ShieldIconImage,
      title: "PF Consultation",
      link: '/pf',
      price:'799'
     // description: "Make your GST Filing simpler and faster with us.We will files all types of GST return applicable on you i.e GSTR-1, GSTR-3B, GSTR-9.We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    },
    {
      imageSrc: ShieldIconImage,
      title: "GST Filling",
      link: '/gstfill',
      price:'999'
      //description: "Make your GST Filing simpler and faster with us.We will files all types of GST return applicable on you i.e GSTR-1, GSTR-3B, GSTR-9.We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    },

    {
      imageSrc: ShieldIconImage,
      title: "LLP Registrations",
      link: '/',
      price:'6999'
      //description: "Make your GST Filing simpler and faster with us.We will files all types of GST return applicable on you i.e GSTR-1, GSTR-3B, GSTR-9.We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    },

    {
      imageSrc: ShieldIconImage,
      title: "Company Registration",
      link: '/',
      price:'8999'
      //description: "Make your GST Filing simpler and faster with us.We will files all types of GST return applicable on you i.e GSTR-1, GSTR-3B, GSTR-9.We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    },
   
    // { imageSrc: SupportIconImage, title: "Video Marketing" },
    // { imageSrc: CustomizeIconImage, title: "Customer Relation" },
    // { imageSrc: ReliableIconImage, title: "Product Outreach" },
    // { imageSrc: FastIconImage, title: "PR Campaign" },
    // { imageSrc: SimpleIconImage, title: "Product Expansion" }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Our Professional <span tw="text-primary-500">Services</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <div className="cs-serviceBoxWrap">
            <NavLink  href={card.link}>
              <Card>
                <span className="imageContainer">
                  <img src={card.imageSrc || defaultCardImage} alt="" />
                </span>
                <span className="textContainer">
                  <span className="title">{card.title || "Fully Secure"}</span>
                  {/* <p className="description">
                    {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                  </p> */}
                </span>
                <div class="cs-servicePrice">₹ {card.price}</div> 
              </Card>
              </NavLink>
            </div>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
