import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import MainFeature3 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import FAQ from "components/faqs/SingleCol.js";
import FeaturesList from "components/features/DashedBorderSixFeatures";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import WaImg from "images/waus.png"

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Service.</HighlightedText><br/>
            Call us now <HighlightedText>(+91)8700701156</HighlightedText>
          </>
        }
      />
      {/* <MainFeature
        subheading={<Subheading></Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <div className="wausImg">
      <a href="https://wa.me/918700701156">
      <img src={WaImg} />
      </a>
    </div> */}
    <FeatureStats/>
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <FeaturesList />
      <MainFeature2
        subheading={<Subheading></Subheading>}
        heading={
          <>
            WHY US <br/><HighlightedText><span tw="text-primary-500">WHY meraCAhai</span></HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
      description: "We choose Professionalism",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />
      
      <Pricing
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "199",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: [""]
          },
          {
            name: "Business",
            price: "399",
            duration: "Quaterly",
            mainFeature: "For Small Businesses",
            features: [""],
            featured: true
          },
          {
            name: "Enterprise",
            price: "4999",
            duration: "Yearly",
            mainFeature: "For Large Companies",
            features: [""]
          }
        ]}
      />
       <MainFeature3
        heading={<>Professional <HighlightedText>CAs</HighlightedText> <HighlightedText>at your service</HighlightedText></>}
        hideSubdetails={true}
      />
      <Testimonial
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://lh3.googleusercontent.com/a-/AOh14GiWq_pBzaAKgit0InbyORyU0OR2M1pTZqe1Eg4mWgQ=s88-w88-h88-c-k",
            heading: "Amazing User Experience",
            quote:
              "You guys are amazing and super fast loved your service will keep in touch.",
            customerName: "Parth Sharma",
            customerTitle: ""
          },
          {
            stars: 5,
            profileImageSrc:
              "https://lh3.googleusercontent.com/a-/AOh14GjOi6z8NNJBy_fr7eCOdaqC_HGcgm015VRDlR_35BE=s56-w56-h56-c-k",
            heading: "Robin was my RM and he was so good with his work, Appreciated.",
            // quote:
            //   "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Avinash Verma",
            customerTitle: "CEO, The Novelty Prints Pvt Ltd"
          },
          {
            stars: 5,
            profileImageSrc:
              "https://lh3.googleusercontent.com/a-/AOh14Ghp28LUoDTsBretFnDk1EgWXI0lhH8Bj9nvqcWl8A=s88-w88-h88-c-k",
            heading: "Great Job, I Will keep coming back",
            // quote:
            //   "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Rohit Kumar Vashishtha",
            customerTitle: "Senior Software Engineer, TOTHENEW"
          },
          {
            stars: 5,
            profileImageSrc:
              "https://lh3.googleusercontent.com/a-/AOh14GiEQGIDWEYItxxLA_kAbCpWgffLWu4gy_M3fSIvow=s88-w88-h88-c-k",
            heading: "Love the team Experience and really easy to get anything done!",
            // quote:
            //   "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Kavita Bist",
            customerTitle: "Senior Software Engineer, The Web Plant Pvt Ltd"
          },
          {
            stars: 5,
            profileImageSrc:
              "https://lh3.googleusercontent.com/a-/AOh14GhV5BS3xzITg68xL2Y9aueZCOnVJJMt363ChCWZZA=s56-w56-h56-c-k",
            heading: "Great Team ",
            // quote:
            //   "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Harsh Mehrana",
            customerTitle: "Senior Software Engineer, The Web Plant Pvt Ltd"
          },
          
        ]}
      />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "How can I share data with you for preparing GST Return with you?",
            answer:
              "You can share a clear photo of all of your purchase and sale bill via WhatsApp to 8700701156 . If you are engaged in selling via an online portal such as Amazon, Flipkart, PayTm etc. then you can share the login details so we can log in into your seller account and download the relevant reports for GST filing. Also, we share the relevant videos with you so you can download the report on your own and send it over via email or WhatsApp. You can also share your software data like Tally etc or give us access to your online account software like QuickBooks, Xero etc."
          },
          // {
          //   question: "What is the process of challan payment in case some GST amount is payable?",
          //   answer:
          //     "We will prepare Challan for the GST amount payable in the GST portal so you can log in into your account and pay directly to the Government. In case you don’t have internet banking or GST portal doesn’t support your bank, then we make GST payment on your behalf from our bank account once you deposit the money into our bank account. We will then share original challan receipt with you for your future reference."
          // },
         
          // {
          //   question: "What is your process for GST filing?",
          //   answer:
          //     "Just WhatsApp US"
            
          // },
         
          {
            question: "When you want me to submit the data for GST return?  ",
            answer:
              "Composition Scheme – The due date for filing the GSTR4 is 18th from the end of the quarter so request you to share the data on or before 12th from the end of the quarter. Regular Scheme (Monthly GSTR3B and Quarterly GSTR1) – The due date for filing GSTR3B is 20th of each month, so we request to submit data on or before 15th of each month. We collect all the required information at the time of preparing GSTR3B, so we do not require any other data for preparing GSTR1. Regular Scheme (Monthly GSTR3B and Monthly GSTR1) – The due date for filing GSTR1 is 11th of each month, so we request to submit data on or before 05th of each month. We collect all the required information at the time of preparing GSTR1, so we do not require any other data for preparing GSTR3B which has a due date of 20th of each month. However, if you want to submit the purchase details later on, then we request you to submit all the purchase on or before 15th of each month."
          },
         
          // {
          //   question: "What are the additional things which are included in your GST return service package?",
          //   answer:
          //     "The below services are available to our GST return service package customer without any additional charges: –

          //     1. GST Registration changes such as mobile number, email, place of business, additional place of business, adding signatory etc.
              
          //     2. Request for Letter of Undertaking in case of export of goods or services.
              
          //     3. Challan payment on your behalf"
          // },
         
          // {
          //   question: "Do you charge any fee for data entry if I provide you all the sales invoice and purchase bills in soft copy such as scan, photo, pdf, word format etc.?",
          //   answer:
          //     "There is no fee if you provide us with less than 100 scans per return period. However, we charge a nominal fee of Rs. 2 per scan in case the total items is more than 100, so for example if you are having 175 scans (100 sales invoice and 75 purchase bills) then we will charge you Rs 2 per scan for 75 scan (i.e. additional fee of Rs 150) which is over and above the limit of 100 scan per return period. No other charges will apply if you can provide us with data in excel in the required format."
          // },

          {
            question: "How many days do you require to process the return?",
            answer:
              "We require at least three working days to file the return from the date of receipt of all the sales and purchase details along with payment."
          },
          {
            question: "What is your working hours?",
            answer:
              "We are available from Monday to Saturday from 09 AM till 05 PM. We also sometimes work on Sunday and even late in the evening on the GST return due dates like 11th, 19th, 20th etc."
          },
          {
            question: "Do I have to make payment in advance?",
            answer:
              "Yes, you are required to pay our fee in advance. We accept quarterly payment only (not monthly). In case you want to try our service, then you can pay us for a quarter, and we will file a single return of yours, and if you are not happy with our service, then you can ask us for a complete refund."
          },
          {
            question: "What is your one return money back guarantee?",
            answer:
              "If you are not happy with our service then you can ask for 100% refund after filing a single return, however, if we have shared GST calculation for a period of more than one month than the money back guarantee is not applicable."
          },
          {
            question: "Do I have to pay the same fee even in case of NIL return?",
            answer:
              "Yes, we charge a flat fee from our customer irrespective of the turnover or the number of transactions."
          },
          // {
          //   question: "Do you offer any discount on annual pricing?",
          //   answer:
          //     "Yes, we offer a 10% discount on the annual fee. Annual fee mean when you make payment for our service for an upcoming period of 12 months in a single time."
          // },

          // {
          //   question: "Does this plan include the fee for GSTR9 Annual Return?",
          //   answer:
          //    "Above pricing plans includes the fee for Annual Return if you hired us for filing GST return for the entire year."
          // },

          // {
          //   question: "Do you provide any acknowledgement for the return filled?",
          //   answer:
          //    "Yes, we will share all the acknowledgement via email as well as WhatsApp after every return which we will file on your behalf."
          // },
          // {
          //   question: "Did your plan cover the service for replying to notices send by GST Department?",
          //   answer:
          //    "If you receive a notice such as non-filing of GST returns or general notices, then it is covered under our plan, and there will be no additional fee for it. However, if you receive any particular notice, then it is chargeable additionally."
          // },
          // {
          //   question: "Did you GST plan also cover the fee for Income tax return filing and accounting?",
          //   answer:
          //    "No, the above plans are only for GST return and do not include any income tax return service and accounting. We do provide services for income tax return filing and accounting which are separately chargeable."
          // },
          // {
          //   question: "No, the above plans are only for GST return and do not include any income tax return service and accounting. We do provide services for income tax return filing and accounting which are separately chargeable.",
          //   answer:
          //    "Yes, we will send reminders via WhatsApp and even call us in-case you are not replying to our WhatsApp messages on a regular basis."
          // },
          // {
          //   question: "What is your additional fee for filing old pending GSTR1 or GSTR3B?",
          //   answer:
          //    "We charge an additional fee of Rs 500 per return in case that returns does not fall under the quarter for which you have purchased our service."
          // },
          // {
          //   question: "Can I file NIL return myself and use your services only in the month in which I have some transactions?",
          //   answer:
          //    "If you purchase our service, then it will be for a quarter and required to be used for a continuous period of 3 months. So if we file your return for the month of February than our plan will run from February to April."
          // },
          // {
          //   question: "What is the process of refund of the fee paid to meraCAHai?",
          //   answer:
          //    "Just whatsapp us and we will process a complete refund of the fee paid to us."
          // },
          // {
          //   question: "How many e-Commerce do you cover in the above pricing?",
          //   answer:
          //    "We cover any two e-commerce and offline sales in the above mentioned plan. In case you are having data for more than 2 e-commerce operator then our fee will be Rs 200 additionally per month per e-commerce operator you have."
          // },
          // {
          //   question: "Do you reconcile the GSTR2 reports for GST input?",
          //   answer:
          //    "Yes, we reconcile your GSTR2 report with the input claimed once a year in the month of April/May (after the financial year end)."
          // },
          // {
          //   question: "Do I have to pay the penalty if the delay in return filing is on TaxAdda's end?",
          //   answer:
          //    "No, if the return is filled due to delay in processing on our end then we will pay all of your late fee. However, we will not be responsible if you share the date on or after 15th of the month as the GST portal doesn’t work in the last days of return filings."
          // },
         
        ]}
      />
      {/* <GetStarted/> */}
      <Footer />
     
    </AnimationRevealPage>
  );
}
