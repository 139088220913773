import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import tw from "twin.macro";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Testimonial2 from "components/testimonials/TwoColumnWithImageAndRating.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import FAQ from "components/faqs/SingleCol.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";

export default ({
  title,
  desc,
  subtitle,
  durationPrices,
  features,
  cont,
  type
}) => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
  const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
       //   subheading={<Subheading></Subheading>}
        heading={title}
        buttonRounded={false}
        desc={desc}
        subtitle={subtitle}
        title={title}
        cont={cont}
        type={type}
        //primaryButtonText="See Portfolio"
        imageSrc={customerSupportIllustrationSrc}
      />
      <Pricing durationPrices={durationPrices} features={features}/>
      {/* <Testimonial
        heading="Our Paying Customers"
      /> */}
      {/* <FAQ /> */}
      <Testimonial2
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://lh3.googleusercontent.com/a-/AOh14GiWq_pBzaAKgit0InbyORyU0OR2M1pTZqe1Eg4mWgQ=s88-w88-h88-c-k",
            heading: "Amazing User Experience",
            quote:
              "You guys are amazing and super fast loved your service will keep in touch.",
            customerName: "Parth Sharma",
            customerTitle: "CTO Formi.com"
          },
          {
            stars: 5,
            profileImageSrc:
              "https://lh3.googleusercontent.com/a-/AOh14GjOi6z8NNJBy_fr7eCOdaqC_HGcgm015VRDlR_35BE=s56-w56-h56-c-k",
            heading: "Robin was my RM and he was so good with his work, Appreciated.",
            // quote:
            //   "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Avinash Verma",
            customerTitle: "CEO, The Novelty Prints Pvt Ltd"
          },
          {
            stars: 5,
            profileImageSrc:
              "https://lh3.googleusercontent.com/a-/AOh14Ghp28LUoDTsBretFnDk1EgWXI0lhH8Bj9nvqcWl8A=s88-w88-h88-c-k",
            heading: "Great Job, I Will keep coming back",
            // quote:
            //   "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Rohit Kumar Vashishtha",
            customerTitle: "Senior Software Engineer, TOTHENEW"
          },
          {
            stars: 5,
            profileImageSrc:
              "https://lh3.googleusercontent.com/a-/AOh14GiEQGIDWEYItxxLA_kAbCpWgffLWu4gy_M3fSIvow=s88-w88-h88-c-k",
            heading: "Love the team Experience and really easy to get anything done!",
            // quote:
            //   "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Kavita Bist",
            customerTitle: "Senior Software Engineer, The Web Plant Pvt Ltd"
          },
          {
            stars: 5,
            profileImageSrc:
              "https://lh3.googleusercontent.com/a-/AOh14GhV5BS3xzITg68xL2Y9aueZCOnVJJMt363ChCWZZA=s56-w56-h56-c-k",
            heading: "Great Team ",
            // quote:
            //   "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Harsh Mehrana",
            customerTitle: "Senior Software Engineer, The Web Plant Pvt Ltd"
          },
          
        ]}
        />
      <Footer/>
    </AnimationRevealPage>
  );
};
